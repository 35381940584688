import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import Post from '../components/Post'

class ArtRoute extends React.Component {
  render() {
    const { title, titleAction } = this.props.data.site.siteMetadata
    const items = [];

    const posts = this.props.data.allMarkdownRemark.edges;
    posts.forEach(post => {
        items.push(<Post data={post} key={post.node.fields.slug} />)
    })

    return (
      <Layout>
        <Helmet><title>{titleAction} making art</title></Helmet>
        <div>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
                <div className="page">
                    <h1 className="page__title">Michael is making art</h1>
                    <div className="page__body">
                      <p>
                        I occassionally make things! You can see some of my projects here.
                      </p>
                    </div>
                </div>
                {items}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ArtRoute

export const pageQuery = graphql`
  query ArtQuery {
    site {
      siteMetadata {
        title
        titleAction
        subtitle
        menu {
          label
          path
        }
        author {
          name
          email
          instagram
          github
          rss
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          layout: { eq: "post_art" }
          draft: { ne: true }
          show_in_feed: { ne: false }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
            categorySlug
          }
          frontmatter {
            layout
            title
            date
            category
            description
            description_image {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                    )
                }
            }
          }
        }
      }
    }
  }
`
